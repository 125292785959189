.page--faq {
	padding-top: 100px;

	@include media-breakpoint-down(sm) {
		padding-top: 24px;
	}

	section {
		margin-bottom: 80px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 40px;
		}

	}

	.container {
		max-width: 1110px;
	}

	h1 {
		font-family: Arial, Helvetica;
		font-weight: bold;
		@include font-rem(56px, 64px);
		color: $blue;
		margin-bottom: 80px;
		text-align: center;

		@include media-breakpoint-down(sm) {
			@include font-rem(44px, 56px);
			margin-bottom: 40px;
		}

	}

	h2 {
		@include font-rem(32px, 40px);
		margin-bottom: 40px;

		@include media-breakpoint-down(sm) {
			@include font-rem(26px, 32px);
			margin-bottom: 20px;
		}

	}

	.faq {
		margin-top: 62px;
		margin-bottom: 40px;

		&.faq--other {

			h2 {
				@include font-rem(24px, 30px);
			}

		}

	}

	.faq-item {
		border-bottom: 1px #eeeeee solid;
		padding: 5px 0;
		color: #000000;

		&:first-of-type {
			border-top: 1px #eeeeee solid;
		}

		&.open {

			h3 {
				font-weight: 700;
			}

			h3 .svg {
				transform: rotate(180deg);

				polygon {
					fill: $blue;
				}

			}

		}

		&.faq-item--full-width {

			.faq-item__detail{

				@include media-breakpoint-down(sm) {
					padding-left: 0;
				}

			}

		}

		> h3 {
			display: block;
			@include font-rem(21px, 32px);
			cursor: pointer;
			position: relative;
			padding: 19px 0 19px 62px;
			transition: font-weight .4s ease-in-out;
			font-weight: normal;
			font-family: Arial, Helvetica;

			@include media-breakpoint-down(sm) {
				@include font-rem(16px, 21px);
				padding: 12px 0 12px 48px;
			}

			&:hover {
				color: #000;
			}

			.svg {
				position: absolute;
				left: 0;
				top: 25px;
				transition: all .2s linear;

				@include media-breakpoint-down(sm) {
					width: 24px;
					top: 12px;
				}

			}

		}

		&__detail {
			display: none;
			padding: 0 24px 16px 62px;
			max-width: 970px;

			@include media-breakpoint-down(sm) {
				padding: 0 0 16px 48px
			}

			p:last-child {
				margin-bottom: 0;
			}

		}

	}

	.show-more {
		border-radius: 40px;
	}

}