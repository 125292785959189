.page--search {
  padding-top: 100px;

  @include media-breakpoint-down(sm) {
    padding-top: 24px;
  }

  h2 {
    @include font-rem(48px, 58px);
    text-align: center;
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
      @include font-rem(32px, 38px);
    }

  }

  section.availability {

    > .container {
      max-width: 847px;
    }

    .availability-container {
      margin-top: 32px;
    }

  }

}
