.bg-white {
    background: #fff;
}

.bg-yellow {
    background: $yellow;
}

.bg-blue {
    background: $blue;
}
