header {
	background: $yellow;
	padding: 22px 0;
	box-shadow: 0 2px 63px rgba(0, 0, 0, 0.07);
	position: relative;

	@include media-breakpoint-down(sm) {
		height: 72px;
	}

	.container {
		display: flex;
		align-items: center;

		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}

	}

	.nav-handler {
		display: none;
		width: 32px;
		height: 24px;
		position: absolute;
		right: 24px;
		top: 24px;
		cursor: pointer;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .5s ease-in-out;
		-moz-transition: .5s ease-in-out;
		-o-transition: .5s ease-in-out;
		transition: .5s ease-in-out;

		@include media-breakpoint-down(sm) {
			display: block;
		}

		&.open {

			span {

				&:nth-child(1) {
					top: 18px;
					width: 0%;
					left: 50%;
				}

				&:nth-child(2) {
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
				}

				&:nth-child(3) {
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}

				&:nth-child(4) {
					top: 12px;
					width: 0%;
					left: 50%;
				}

			}

		}

		span {
			display: block;
			position: absolute;
			height: 4px;
			width: 100%;
			background: $blue;
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			@include transition(all .25s ease-in-out);

			&:nth-child(1) {
				top: 0;
			}

			&:nth-child(2),
			&:nth-child(3) {
				top: 10px;
			}

			&:nth-child(3) {
				top: 10px;
			}

			&:nth-child(4) {
				top: 20px;
			}

		}

	}

	nav {
		display: flex;
		flex-direction: row-reverse;
		margin-left: 60px;
		align-items: center;
		flex: 1;

		@include media-breakpoint-only(lg) {
			margin-left: 35px;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column-reverse;
			margin-left: 45px;
		}

		@include media-breakpoint-down(sm) {
			display: none;
			width: 100%;
			height: 100vh;
			position: absolute;
			left: 0;
			top: 72px;
			background: $yellow;
			margin-left: 0;
			z-index: 5;
			box-shadow: 0 2px 63px rgba(0, 0, 0, 0.07);
			padding: 24px;
		}

		ul {
			display: flex;

			@include media-breakpoint-down(md) {
				margin-bottom: 20px;
			}

			@include media-breakpoint-down(sm) {
				display: block;
			}

			li {
				flex: 0 0 auto;

				&.selected {

					a {
						color: #fff;
					}

				}

			}

		}

		a {
			@include font-rem(20px, 20px);
			text-decoration: none;
			padding: 0 20px;
			transition: all .1s linear;

			@include media-breakpoint-down(sm) {
				padding: 20px 0;
				display: block;
			}

			&:hover {
				color: #fff;
			}

		}

	}

	form {
		margin: 0 0 0 auto;
		position: relative;
		right: -50px;
		width: 30%;
		opacity: 0;

		@include max-width(1400px) {
			right: 0;
		}

		@include media-breakpoint-down(lg) {
			width: 25%;
			@include font-rem(12px, 16px);
		}

		@include media-breakpoint-down(md) {
			@include font-rem(16px, 24px);
		}

		@include media-breakpoint-down(md) {
			width: 100%;
			margin-left: 35px;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
			margin-left: 0;
		}

		select {
			background-color: #f1f3f7;
			height: 40px;
			width: 168px;
			padding-left: 49px;

			@include media-breakpoint-down(md) {
				width: 100%;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
			}

		}

		button {
			position: absolute;
			left: -33px;
			top: 0;
			height: 35px;
			width: 37px;
			background-color: #fff;
			border-radius: 4px;

			@include media-breakpoint-down(sm) {
				left: 0;
			}

			img {
				margin-left: 10px;
			}
		}

	}

}

footer {

	.content {
		width: 100%;
		border-top: 1px #e3e6ec solid;
		padding-top: 35px;
		display: flex;
		align-items: center;
		padding-bottom: 35px;

		@include media-breakpoint-down(sm) {
			display: block;
		}

	}

	img {
		max-width: 170px;
		margin: 0 auto 0 0;
	}

	nav {

		@include media-breakpoint-down(sm) {
			margin: 16px 0;
		}

		ul {
			display: flex;
			align-items: center;

			@include media-breakpoint-down(md) {
				display: block;
			}

			a {
				text-decoration: none;
				color: #000000;
				@include font-rem(12px, 21px);
				padding: 0 12px;

				@include media-breakpoint-down(sm) {
					padding: 0;
				}

				&:hover {
					text-decoration: underline;
				}

			}

		}

	}

	span {
		@include font-rem(11px, 18px);
		color: #82786f;
		margin: 0 0 0 auto;
		width: 170px;
		text-align: right;
	}

}

.page {
	margin-bottom: 80px;
}

// Global sections
section.contact {
	background: #fff;
	text-align: center;
	padding: 50px 0;

	.col:first-child {
		border-right: 2px #e3e6ec solid;

		@include media-breakpoint-down(sm) {
			border-right: 0;
			margin-bottom: 40px;
		}

	}

	.img-wrapper {
		height: 56px;
		margin-bottom: 16px;
	}

	.svg {
		margin: 0 auto;
		width: 43px;
		height: 43px;

		&.phone {
			margin-top: 10px;
		}

		&.chat {
			width: 56px;
			height: 56px;
		}

	}

	h3 {
		@include font-rem(24px, 30px);
		margin-bottom: 15px;
	}

	p {
		margin-bottom: 16px;
	}

	.message p {
		margin-bottom: 24px;
	}

	.phone {
		color: $yellow;
		@include font-rem(24px, 30px);
		text-decoration: none;
	}

	.small {
		@include font-rem(12px, 24px);

		@include media-breakpoint-down(sm) {
			border-bottom: 1px #e3e6ec solid;
			padding-bottom: 40px;
		}

	}

}

.availability-container {
	background-color: #f5f7fa;
	border-radius: 8px;
	padding: 32px;

	@include media-breakpoint-down(sm) {
		padding: 24px;
	}

	.availability-box {
		background: $yellow;
		border-radius: 8px;
		padding: 24px;

		h3 {
			font-family: Arial, Helvetica;
			@include font-rem(16px, 24px);
			font-weight: bold;
		}

		a {
			color: #000000;
			@include font-rem(12px, 18px);
		}

		form {
			display: flex;
			margin: 12px 0;

			@include media-breakpoint-down(sm) {
				display: block;
			}

			button {
				background: $blue;
				padding: 16px;
				border-radius: 8px;
				margin-left: 8px;

				@include media-breakpoint-down(sm) {
					width: 100%;
					margin-top: 8px;
					margin-left: 0;
					padding: 6px 0;
				}

				.svg {
					display: block;
					width: 22px;
					height: 22px;
					margin: 0 auto;
				}

				path {
					fill: #fff;
				}

			}

		}

	}

	.availability-results {
		display: none;
		background-color: #ffffff;
		border-radius: 8px;
		padding: 24px;
		margin-top: 16px;

		.title {
			color: #82786f;
			margin-bottom: 20px;
			text-align: center;
		}

		.featured {
			margin-top: 24px;

			h3 {
				color: $blue;
			}

		}

		ul {
			display: flex;
			flex-wrap: wrap;

			@include media-breakpoint-down(sm) {
				display: block;
			}

			li {
				width: calc(100%/4);
				text-align: center;
				margin-bottom: 32px;
				padding: 0 24px;

				@include media-breakpoint-down(sm) {
					width: 100%;
				}

			}

			img {
				margin: 0 auto 10px auto;
				max-width: 75%;
			}

		}

		.featured-company {
			cursor: pointer;
		}

		.availability-item {
			background-color: #f5f7fa;
			padding: 24px;

			h3 {
				margin-bottom: 5px;
				color: #002776;
			}

			p {
				color: #000000;
				@include font-rem(14px, 21px);
				margin-bottom: 3px;
			}

			span {
				color: #82786f;
				display: block;
				margin-bottom: 16px;
				@include font-rem(14px, 21px);
			}

			a {
				color: #000000;
				@include font-rem(12px, 18px);
			}

		}

	}

}

.laptop {
	//width: 479px;
	//height: 286px;
	//background: url("../images/laptop.png") no-repeat;
	//background-size: cover;
	text-align: center;
	@include font-rem(32px, 40px);
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: noto_sansbold;
	margin: 0 auto;
	position: relative;

	@include media-breakpoint-only(md) {
		@include font-rem(26px, 32px);
	}

	@include media-breakpoint-down(xs) {
		@include font-rem(22px, 30px);
	}

	&:hover {

		path {
			fill: $blue;
		}

	}

	&.laptop--size1 {
		@include font-rem(24px, 32px);

		@include media-breakpoint-only(md) {
			@include font-rem(20px, 26px);
		}

		@include media-breakpoint-down(xs) {
			@include font-rem(18px, 24px);
		}

	}

	.svg {
		display: block;
		margin: 13px auto 0 auto;

		@include media-breakpoint-only(md) {
			width: 40px;
			height: 40px;
			margin-top: 8px;
		}

		@include media-breakpoint-down(xs) {
			width: 34px;
			height: 34px;
			margin-top: 8px;
		}

		path {
			transition: all .2s linear;
		}

	}

	span {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		padding-bottom: 10px;

		@include media-breakpoint-down(sm) {
			padding-bottom: 10px;
		}

		em {
			font-style: normal;
			&.l-2 {
				margin-top: -12%;
				@include media-breakpoint-down(md) {
					margin-top: -7%;
				}
				@include media-breakpoint-down(sm) {
					margin-top: -12%;
				}
			}
		}

	}

}


#breakpoint-info {
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 5px 10px;
	z-index: 999;
	background: rgba(255,255,255,.5);
	color: #000;
	font-size: 12px;

	&::before {

		@include media-breakpoint-down(xs) {
			content: "xs"
		}

		@include media-breakpoint-only(sm) {
			content: "sm"
		}

		@include media-breakpoint-only(md) {
			content: "md"
		}

		@include media-breakpoint-only(lg) {
			content: "lg"
		}

		@include media-breakpoint-only(xl) {
			content: "xl"
		}

	}

}

.select2-container {
	@include media-breakpoint-down(lg) {
		@include font-rem(12px, 16px);
	}

	@include media-breakpoint-down(md) {
		@include font-rem(16px, 24px);
	}
	.select2-results__option {
		@include media-breakpoint-down(lg) {
			margin-right: 16px;
		}

		@include media-breakpoint-down(md) {
			margin-right: 19px;
		}
	}
}


.found-none {
	text-align: center;
	font-weight: bold;
	background: #f5f7fa;
	border-radius: 4px;
	padding: 6px 10px;
}
