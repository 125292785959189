.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 0;
  border-radius: 4px;
  height: 35px;
  line-height: 35px;
  padding: 0 16px;
}

.select2-container .select2-search--inline .select2-search__field {
  margin: 0;
}

li.select2-results__option {
  padding-left: 0 !important;
}

.select2.select2-container.select2-container--default{
  @include media-breakpoint-down(sm) {
    width: 90% !important;
    margin-left: 33px !important;
  }
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
}

.select2-dropdown {
  border: 0;
  box-shadow: 0 28px 35px rgba(0, 0, 0, 0.24);
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  /*background-color: $green;
  margin: 0;
  padding-left: 19px;
  padding-right: 19px;
  border: 0;
  margin-top: -1px;*/
  background-color: transparent;
  color: #002776;
  font-weight: bold;
}

.select2-results__option {
  padding: 8px 0;
  margin: 0 19px 0 8px;
  border-bottom: 1px #91a9a7 solid;

  &:last-child {
    border-bottom: 0;
  }

}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  color: #333333;
  font-size: 20px;

  span {
    display: none;
  }

}

.select2-results__options {
  background: #f5f5f5;
}
