html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

html {
    font-size: 16px;
    line-height: 24px;
}

body {
    color: $blue;
    font-family: Arial, noto_sansregular, Helvetica, sans-serif;
    font-weight: normal;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;

    &.scroll-disabled {
        overflow: hidden;
    }

}

a {
    color: $blue;
    outline: none;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

}

textarea {
    resize: none;
    outline: none;
    border: 0;

    &.inputSkin {
        text-transform: none;
    }

}

iframe {
    border: none;
}

p {
    margin: 0;
    margin-bottom: 20px;

    &:last-of-type,
    &:last-child {
        margin-bottom: 0;
    }

}

fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

label {
    display: block;
    cursor: pointer;
}

input {
    outline: 0;
    border: 0;
}

*[type="submit"] {
    cursor: pointer;
}

img {
    display: block;
    max-width: 100%;
    border: 0;
}

h1, h2, h3, h4, h5 {
    font-weight: normal;
    margin: 0;
    font-family: noto_sansbold;
}

strong {
    font-weight: bold;
}

.clear {
    clear: both;
    width: 100%;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }

}

ol {
    margin: 0;

    &.alpha {
        list-style-type: lower-alpha;
    }

}

ul.list {

    li {
        position: relative;
        padding-left: 24px;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: "";
            display: block;
            background: $yellow;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            position: absolute;
            left: 0;
            top: 4px;
        }

    }

}

ul.check {

    li {
        position: relative;
        padding-left: 48px;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: "";
            display: block;
            background: url("../images/icons/check.svg") 1px 3px no-repeat;
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 2px;
            border: 2px solid #ffffff;
        }

    }

}

.container {
    padding-right: 24px;
    padding-left: 24px;
}

figure {
    margin: 0;
    padding: 0;
}

button {
    border: none;
    outline: 0;
    background-color: transparent;
    padding: 0;
}

canvas {
    max-width: 100%;
    max-height: 100%;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.svg {
    display: inline-block;
}

.hidden {
    display: none;
}

.visible{
    opacity: 1;
}

* {
    box-sizing: border-box;
}

.wow {
    visibility: hidden;
    opacity: 0;
}
