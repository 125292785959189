/* Colors */
$yellow: #fdc82f;
$blue: #002776;


/* Conversion rate font size (default font size)*/
$rem-base-font: 16px !default;
$base-font-line: 24px !default;


/* Effects */
$transition-duration: .25s;


/* Bootstrap */
$grid-columns:      12;
$grid-gutter-width: 24px;

$grid-breakpoints: (
	// Extra small screen / phone
	xs: 0,
	// Small screen / phone
	sm: 576px,
	// Medium screen / tablet
	md: 768px,
	// Large screen / desktop
	lg: 992px,
	// Extra large screen / wide desktop
	xl: 1200px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1280px
);

$spacer: 8px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
(
	0: 0,
	1: ($spacer * 1),
	2: ($spacer * 2),
	3: ($spacer * 3),
	4: ($spacer * 4),
	5: ($spacer * 5),
	6: ($spacer * 6),
	7: ($spacer * 7),
	8: ($spacer * 8),
	9: ($spacer * 9),
	10: ($spacer * 10),
	11: ($spacer * 11),
	12: ($spacer * 12),
	13: ($spacer * 13),
	14: ($spacer * 14),
	15: ($spacer * 15)
),
$spacers
);
