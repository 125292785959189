/* Background opacity shortcode */
@mixin background-opacity($color, $opacity: 0.3) {
	background: $color; /* The Fallback */
	background: rgba($color, $opacity);
}


/* Convert px to rem */
@mixin font-rem($font-size, $line-height: 0) {
	font-size: $font-size;
	font-size: ($font-size / $rem-base-font) * 1rem;
	@if ($line-height > 0) {
		line-height: $line-height;
		line-height: ($line-height / $rem-base-font) * 1rem;
	}
}


/* Effects */
@mixin transition($property, $duration, $process) {
	transition: $property $duration $process;
}


/* Breakpoints */
@mixin max-width($max-width) {
	
	@media screen and ( max-width: $max-width ) {
		@content;
	}
	
}

@mixin min-width($min-width) {
	
	@media screen and ( min-width: $min-width ) {
		@content;
	}
	
}


@mixin min-max-width($min-width, $max-width) {
	
	@media screen and ( min-width: $min-width ) and ( max-width: $max-width ){
		@content;
	}
	
}
