.page--prices {
	padding-top: 100px;

	@include media-breakpoint-down(sm) {
		padding-top: 24px;
	}

	> .container {
		max-width: 850px;
	}

	h1 {
		font-family: Arial, Helvetica;
		font-weight: bold;
		@include font-rem(56px, 64px);
		color: $blue;
		margin-bottom: 80px;
		text-align: center;

		@include media-breakpoint-down(sm) {
			@include font-rem(44px, 56px);
			margin-bottom: 40px;
		}

	}

	.box {
		background-color: $yellow;
		padding: 24px;
		border-radius: 8px;
		font-family: Arial, Helvetica;
		@include font-rem(16px, 20px);
		text-align: center;
		//margin-bottom: 80px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 24px;
		}

		h2 {
			color: $blue;
			margin-bottom: 20px;
			font-family: Arial, Helvetica;
			@include font-rem(16px, 20px);
			font-weight: bold;
			text-align: left;

			span {
				font-weight: normal;
			}

		}

		p {
			color: $blue;
			text-align: center;
			@include font-rem(12px, 18px);
			max-width: 360px;
			margin: 0 auto 24px auto;
		}

		li {
			margin-bottom: 16px;

			@include media-breakpoint-down(sm) {
				@include font-rem(14px, 20px);
			}

			&:before {
				background: $blue;
			}

		}

		.btn {
			border-radius: 40px;
			margin: 0 auto;
			background-color: $blue;
			color: white;
			&:hover {
				color: $blue;
				background-color: white;
			}
		}

		.content {
			background: #fff;
			border: 1px solid #bcbdbc;
			padding: 16px;
			color: $blue;
			text-align: left;
		}

		.group:not(:last-child) {
			margin-bottom: 24px;
		}

	}

}
