input,
textarea {
    width: 100%;
    //background: #f0f0f0;
    font-family: Arial;
    @include font-rem(16px, 22px);
    padding: 10px;
    border-radius: 4px;
    transition: border .2s linear;

    &::placeholder {
        color: #82786f;
        opacity: 1;
    }

    &.error {
        border: 1px $red solid;
    }

    &:focus {
        border-color: $orange;
    }

    &:disabled {
        background: #fff;
    }

}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}



.label_radio,
.label_check {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    line-height: 16px;

    &.error-check {

        &::before {
            background: $red;
        }

    }

    &::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        position: absolute;
        left: 0;
        top: 0;
        border: 1px solid #c2c2c2;
        box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.06);
    }

    &::after {
        content: "";
        display: block;
        width: 10px;
        height: 9px;
        background: red;
        position: absolute;
        left: 3px;
        top: 3px;
        border-radius: 1px;
        transition: all .2s linear;
        opacity: 0;
    }

    &.r_on,
    &.c_on {

        &::after {
            opacity: 1;
        }

    }

}

.label_radio {

    &::before {
        border-radius: 100%;
    }

    &::after {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: $orange;
        left: 4px;
        top: 4px;
    }

}

.label_radio input,
.label_check input {
    position: absolute;
    left: -9999px;
}

label {
    display: flex;
    font-family: muli_bold;
    text-align: left;
    margin-bottom: 5px;

    @include media-breakpoint-down(sm) {
        display: block;
    }

    span.dot {
        @include font-rem(14px, 20px);
    }

}

fieldset {
    margin-bottom: 20px;
}

fieldset.error {

    .selectric {
        border-color: $red;
    }

}

fieldset.disabled {
    opacity: .5;

    label {
        cursor: default;
    }

    .error {
        //display: none !important;
    }

}

span.error:not(.select2-selection),
label.error {
    display: block;
    margin-top: 5px;
    color: $red;
    text-align: left;
    font-family: muli_regular;
}