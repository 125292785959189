.page--cookies {
	padding-top: 100px;
	color: #000;

	@include media-breakpoint-down(sm) {
		padding-top: 24px;
	}

	h1 {
		font-family: Arial, Helvetica;
		font-weight: bold;
		@include font-rem(56px, 64px);
		color: $blue;
		margin-bottom: 80px;
		text-align: center;

		@include media-breakpoint-down(sm) {
			@include font-rem(44px, 56px);
			margin-bottom: 40px;
		}

	}

	h2,
	.perex {
		@include font-rem(21px, 32px);

		@include media-breakpoint-down(sm) {
			@include font-rem(18px, 24px);
		}

	}

	.container {
		max-width: 846px;
	}

	h2 {
		color: $blue;
		margin-bottom: 12px;
		font-family: Arial, Helvetica;
		font-weight: bold;
	}

	.perex,
	.group {
		margin-bottom: 40px;
	}

}