.btn {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    position: relative;
    transition: all .2s linear;
    @include font-rem(16px, 24px);

    @include media-breakpoint-down(sm) {
        @include font-rem(14px, 20px);
    }

    &.btn--type1 {
        background: $yellow;
        border-radius: 8px;
        padding: 12px 32px;
        color: $blue;

        @include media-breakpoint-down(sm) {
            padding: 8px 16px;
        }

    }

    &.btn--type2 {
        border-radius: 8px;
        padding: 10px 32px;
        color: $blue;
        border: 1px $blue solid;
    }


    &.btn--type1:hover,
    &.btn--type2:hover,
    &.btn--type2.active {
        background: $blue;
        color: #fff;
    }

    &.btn--icon {
        display: inline-flex;
        align-items: center;

        &:hover {

            .svg path {
                fill: #fff;
            }

        }

        .svg path {
            transition: all .2s linear;
        }

        img,
        .svg {
            max-width: 23px;
            max-height: 23px;
            margin-right: 16px;

            @include media-breakpoint-down(sm) {
                max-width: 20px;
                max-height: 20px;
                margin-right: 10px;
            }

        }

    }

}
