.page--home {
	background: #f5f7fa;
	margin-bottom: 0;

	.container {
		position: relative;
	}

	section.header {
		background: $yellow;
		padding: 36px 0;
		height: 608px;

		@include min-max-width(1200px, 1450px) {
			height: 516px;
		}

		@include media-breakpoint-down(lg) {
			height: 536px;
		}

		@include media-breakpoint-down(sm) {
			padding: 24px 0;
		}

		@include media-breakpoint-down(md) {
			height: auto;
		}

		h1 {
			font-family: noto_sansbold;
			@include font-rem(64px, 72px);
			text-transform: uppercase;
			color: $blue;
			margin-bottom: 37px;
			margin-top: 56px;

			@include media-breakpoint-down(sm) {
				@include font-rem(44px, 56px);
				margin-top: 0;
				margin-bottom: 30px;
			}

		}

		li {
			position: relative;
			padding-left: 24px;
			@include font-rem(21px, 30px);
			color: $blue;
			margin-bottom: 16px;

			&::before {
				content: "";
				display: block;
				background: url("../images/icons/arrow-right.svg") no-repeat;
				width: 10px;
				height: 16px;
				position: absolute;
				left: 0;
				top: 8px;
			}

		}

		/*.card-wrapper {
			display: flex;
			align-items: center;

			img {
				flex: 1;
			}

		}*/

		img {
			position: absolute;
			right: -145px;

			@include min-max-width(1200px, 1510px) {
				width: 700px;
				right: -24px;
			}

			@include min-max-width(992px, 1199px) {
				right: -35px;
				width: 500px;
				top: 50px;
			}

			@include media-breakpoint-down(md) {
				margin-top: 24px;
				position: relative;
				right: 0;
			}

		}

	}

	section.about {

		.content {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 32px;
			text-align: center;
			border-radius: 5px;

			@include media-breakpoint-down(sm) {
				display: block;
				padding: 24px;
			}

			div {
				width: 100%;
			}

			/*> img {
				margin: 0 auto;
			}*/

			h2 {
				@include font-rem(32px, 40px);
				margin-bottom: 15px;

				@include media-breakpoint-down(sm) {
					@include font-rem(24px, 30px);
				}

			}

			p {
				max-width: 430px;
				margin: 0 auto 25px auto;
			}

		}

	}

}
