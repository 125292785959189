.page--organizations {
	padding-top: 80px;

	@include media-breakpoint-down(sm) {
		padding-top: 24px;
	}

	.box {
		padding: 32px 32px 110px 32px;
		border-radius: 8px;
		font-family: Arial, Helvetica;
		@include font-rem(16px, 20px);
		text-align: center;
		margin-bottom: 80px;
		position: relative;

		@include media-breakpoint-down(sm) {
			margin-bottom: 24px;
			padding: 24px;
		}

		&.box--dark {

			h1,
			h2 {
				color: #fff;
			}

			.btn {
				background: $yellow;
				color: $blue;
				border-color: $yellow;

				&:hover {
					background: transparent;
					color: #fff;
				}

			}

		}

		h1,
		h2 {
			@include font-rem(24px, 30px);
			margin-bottom: 20px;

			@include media-breakpoint-down(sm) {
				@include font-rem(20px, 26px);
			}

		}

		.btn-wrapper {
			position: absolute;
			bottom: 32px;
			left: 0;
			width: 100%;

			@include media-breakpoint-down(sm) {
				position: relative;
				bottom: 0;
			}

		}

		.btn {
			border-radius: 40px;
			background: $blue;
			margin-top: 20px;
			color: #fff;

			&:hover {
				background: transparent;
				color: $blue;
			}
		}

	}

}